import React from 'react'
import ArrowBlue from './arrowBlue.svg'
import ArrowPurple from './arrowPurple.svg'
import ArrowWhite from './arrowWhite.svg'
import styled from 'styled-components'

const Circled = styled.div`
    border-width: 2px;
    border-style: solid;
    border-color: ${props => props.c};
    padding: ${props => (props.size === 'default' ? '16px' : '10px')};
    border-radius: 500px;
    background: url(${props => IconLoader(props)}) center center no-repeat;
    background-size: ${props => (props.size === 'default' ? '50%' : '40%')};
`

export const Circle = props => {
    return <Circled {...props}></Circled>
}

const IconLoader = props => {
    switch (props.arrow) {
        case 'white':
            return ArrowWhite
        case 'purple':
            return ArrowPurple
        case 'blue':
            return ArrowBlue
        default:
            return ArrowWhite
    }
}

Circle.defaultProps = {
    arrow: 'white',
    c: 'black',
    size: 'default',
}
