import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { screenSizes } from '../../../lib/constants'
import { graphql, StaticQuery, Link } from 'gatsby'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Spacer from '../../uielements/Spacer'
import Button from '../../uielements/Button'
import FlexWrapper from '../../uielements/flexWrapper'
import { useTranslation } from 'react-i18next'
import InnerTitle from '../../uielements/InnerTitle'
import RichContent from '../../uielements/RichContent/richContent'

const Rectangle = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    height: 200px;
    padding-left: 240px;
    box-shadow: 0 0 10px #bbb;
    border-radius: 6px;
    border: 1px solid #ccc;
    overflow: hidden;

    @media (max-width: ${screenSizes.lg}px) {
        padding-left: 140px;
    }

    @media (max-width: ${screenSizes.md}px) {
        padding-left: 0;
    }
`

const RectangleImage = styled.div`
  background-image: url('${({ imgObj }) => giveMeTheImage(imgObj)}');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 240px;
  height: 200px;
  position: absolute;
  left: 0;
  
  @media (max-width: ${screenSizes.lg}px) {
      width: 140px;
  }  
  
  @media (max-width: ${screenSizes.md}px) {
      width: 100%;
  }
`

const RectangleBody = styled.div`
    padding: 20px 20px 0 20px;
    height: 140px;
    overflow: hidden;

    @media (max-width: ${screenSizes.sm}px) {
        padding: 20px 5px 0 20px;
    }

    h4 {
        @media (max-width: ${screenSizes.md}px) {
            font-size: 1em;
            z-index: 99999;
            display: block;
            position: absolute;
            left: 10px;
            color: #fff;
            text-shadow: 0 0 4px #000;
        }
    }
`

const ButtonWrapper = styled.div`
    position: absolute;
    right: 20px;
    bottom: 20px;
`

const TextWrapper = styled.div`
    display: block;
    text-align: left !important;
    font-size: 0.8em;

    div {
        line-height: 1.2;
        text-align: left !important;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (max-width: ${screenSizes.md}px) {
            display: none;
        }
    }
`

const SliderWrapper = styled.div`
    margin: 30px 0;

    .slick-slide {
        margin: 0 10px 0 0;

        @media (max-width: ${screenSizes.sm}px) {
            width: calc(100vw - 20px) !important;
        }
    }
`

const Subtitle = styled.h4`
    text-transform: uppercase;
    color: ${({ theme }) => theme.blue};
    font-weight: bold;
    font-size: 1.3em;
    line-height: 1.1em;
    max-width: 400px;
`

const MyBlogBox = ({ data }) => {
    const { t, i18n } = useTranslation()

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        adaptiveHeight: true,
        rows: 1,
        slidesPerRow: 1,
        responsive: [
            {
                breakpoint: screenSizes.sm,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    centerMode: false,
                    dots: true,
                    variableWidth: true,
                },
            },
        ],
    }

    return (
        <>
            {i18n.language === 'el-GR' && (
                <SliderWrapper>
                    <Spacer top="6" />
                    <InnerTitle>{t('blog')}</InnerTitle>
                    <Subtitle>{t('blog-desc')}</Subtitle>
                    <Spacer top="4" />
                    <Slider {...settings}>
                        {data.blogItems.nodes.map(
                            node =>
                                node.node_locale === i18n.language && (
                                    <Rectangle className="rect" key={node.id}>
                                        <Link
                                            to={`/blog/${node.slug}`}
                                            title="blog item"
                                        >
                                            <RectangleImage
                                                imgObj={node.featuredImage}
                                            />
                                        </Link>
                                        <RectangleBody>
                                            <h4>
                                                <strong>{node.title}</strong>
                                            </h4>
                                            <Spacer />
                                            <TextWrapper>
                                                <RichContent
                                                    body={node?.excerpt?.raw}
                                                />
                                            </TextWrapper>
                                            <ButtonWrapper>
                                                <Button
                                                    size="small"
                                                    textColor="#fff"
                                                    link={`/blog/${node.slug}`}
                                                    arrow="blue"
                                                >
                                                    {t('read-more')}
                                                </Button>
                                            </ButtonWrapper>
                                        </RectangleBody>
                                    </Rectangle>
                                )
                        )}
                    </Slider>
                    <Spacer top="6" />

                    <FlexWrapper justifyContent="center" alignItems="center">
                        <Button link="/blog" arrow="blue">
                            {' '}
                            {t('view-all-articles')}{' '}
                        </Button>
                    </FlexWrapper>
                </SliderWrapper>
            )}
        </>
    )
}

// const giveMeTheImage = (obj) => {
//   if (obj) {
//     return obj.file.url
//   }
//   return BlogSlider.defaultProps.imgObj.file.url
// }

const giveMeTheImage = obj => {
    if (obj) {
        return obj.url
    }

    return BlogSlider.defaultProps.imgObj.file.url
}

const BlogSlider = props => {
    return (
        <StaticQuery
            query={graphql`
                query BlogItemsQuery {
                    blogItems: allContentfulBlog(
                        limit: 6
                        filter: { mainArticle: { eq: "yes" } }
                    ) {
                        nodes {
                            id
                            excerpt {
                                raw
                            }
                            node_locale
                            slug
                            title
                            featuredImage {
                                url
                                title
                                gatsbyImageData(
                                    quality: 75
                                    jpegProgressive: true
                                    sizes: "1920, 960, 480"
                                    cropFocus: CENTER
                                )
                            }
                        }
                    }
                }
            `}
            render={data2 => <MyBlogBox data={data2} props={props} />}
        ></StaticQuery>
    )
}

BlogSlider.propTypes = {
    title: PropTypes.string,
    excerpt: PropTypes.string,
    imgObj: PropTypes.object,
}

BlogSlider.defaultProps = {
    title: 'this is the title',
    excerpt: 'this is the excerpt',
    imgObj: {
        file: {
            fileName: 'Default Name',
            url: 'http://via.placeholder.com/300x380',
        },
    },
}

export default BlogSlider
