import React from 'react'
import styled from 'styled-components'
import { navigate } from 'gatsby-link'
import { theme } from '../../../theme'
import { darken } from 'polished'
import { Circle } from './circle'
import Spacer from '../Spacer'

const StyledButton = styled.button`
    border: 2px solid ${props => props.bgColor};
    border-radius: 100px;
    padding: ${props => (props.size === 'default' ? '0.6em 2em' : '0.2em 1em')};
    display: inline-flex;
    box-shadow: 0 0 20px #ccc;
    align-items: center;

    &:hover {
        border-color: ${props => darken(0.05, props.bgColor)};
    }

    span {
        color: ${props => props.textColor};
        font-size: ${props => (props.size === 'default' ? '1em' : '0.8em')};
    }
`

const FilledButton = styled.button`
    border: none;
    background: ${props => props.bgColor};
    color: ${props => props.textColor};
    padding: ${props => (props.size === 'default' ? '0.6em 2em' : '0.2em 1em')};
    border-radius: 100px;
    box-shadow: 0 0 20px #ccc;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
        background: ${props => darken(0.05, props.bgColor)};
    }

    span {
        color: ${props => props.textColor};
        font-size: ${props => (props.size === 'default' ? '1em' : '0.6em')};
    }
`

const Button = props => {
    return props.filled ? (
        <FilledButton {...props} onClick={() => navigate(props.link)}>
            <span>{props.children}</span>
            <Spacer left="1" right="1" md={{ left: '0.2', right: '1' }} />
            <Circle size={props.size} c={props.textColor} arrow={props.arrow} />
        </FilledButton>
    ) : (
        <StyledButton {...props} onClick={() => navigate(props.link)}>
            <span>{props.children}</span>
            <Spacer left="1" right="1" md={{ left: '0.2', right: '1' }} />
            <Circle size={props.size} c={props.textColor} arrow={props.arrow} />
        </StyledButton>
    )
}

Button.defaultProps = {
    link: '/',
    filled: true,
    bgColor: `${theme.purple}`,
    textColor: '#fff',
    accentColor: `${theme.blue}`,
    arrow: 'white',
    size: 'default',
}

export default Button
